@use '@angular/material' as mat;

@use 'variables';

@import 'assets/fonts/material-icons/material-icons.css';

/* You can add global styles to this file, and also import other style files */
@import '_reset';

body {
  background-color: black;
  transition: opacity 0.5s ease;
  opacity: 1;
  &.theme-manager--animate {
    opacity: 0;
    pointer-events: none;
  }
  scrollbar-color: var(--mat-theme-col-primary) var(--mat-sidenav-container-background-color);
}

.icon-plus-text {
  display: flex;
  align-items: center;
  mat-icon {
    margin-right: variables.$prisma-padding-small * 0.2;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.no-margin {
  margin: 0;
}

.display-none {
  display: none;
}

.mat-toolbar-spacer {
  flex: 1 1 auto;
}

idv-instance-client-display,
idv-instance-customer-display,
idv-agent-display.default {
  display: grid;
  cursor: pointer;
  grid-template-areas:
    'avatar name'
    'avatar personnelNo';
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content min-content;
  align-items: center;
  gap: 0 variables.$prisma-padding * 0.2;

  app-avatar {
    grid-area: avatar;
    height: variables.$avatar-size;
    width: variables.$avatar-size;
  }

  .name {
    grid-area: name;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .personnelNo {
    align-self: self-start;
    grid-area: personnelNo;
    opacity: 0.6;
    font-size: 1rem;
    line-height: 1rem;
  }

  &.big {
    .name {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    app-avatar {
      height: variables.$avatar-size * 2;
      width: variables.$avatar-size * 2;
    }
  }

  &.small {
    .name {
      font-size: 1rem;
      line-height: 1.2rem;
    }
    app-avatar {
      height: variables.$avatar-size * 0.75;
      width: variables.$avatar-size * 0.75;
    }
  }
}

.mdc-dialog__content {
  height: 100%;
  max-height: 100vh;
}

.mat-sidenav-content .mat-toolbar {
  z-index: 1;
  position: sticky;
  overflow: hidden;
  background: none;
  column-gap: 1rem;
}

.mat-mdc-dialog-surface > *:first-of-type {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-auto-flow: row;
  height: 100%;
  gap: variables.$prisma-padding-small;
  box-sizing: border-box;
  overflow: hidden;

  .mdc-dialog__content {
    height: 100%;
    max-height: 100vh;
    padding: 0;
  }

  .mdc-dialog__title::before {
    height: auto;
  }
}

.edit-icon-group {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 2rem;
  padding: 1rem;
  & > mat-icon {
    opacity: 0.6;
    margin-top: 0.2rem;
    width: variables.$edit-icon-size;
    height: variables.$edit-icon-size;
    font-size: variables.$edit-icon-size;
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  &:nth-child(even) {
    background-color: rgb(
      from var(--mdc-list-list-item-hover-state-layer-color) R G B /
        calc(var(--mdc-list-list-item-hover-state-layer-opacity) * 0.5)
    );
  }
}

.odd-even-hover-row:nth-child(even),
.mat-mdc-table .mat-mdc-row.mdc-data-table__row:nth-child(even) {
  background-color: hsl(from var(--mat-table-background-color) h s calc(l * 0.85));
}

mat-cell.mat-mdc-cell {
  padding: variables.$prisma-padding-small;
  border: 0px;
}

.overflow-ellipsis {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

a.overflow-ellipsis,
button.overflow-ellipsis {
  .mdc-button__label {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

header .content-with-spinner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4rem;
  align-items: center;
  line-height: 1;
  h1 {
    line-height: 1;
    margin: 0;
  }
  idv-loading-indicator {
    position: static;
    max-width: 1rem;
    max-height: 1rem;
    justify-self: end;
  }
}

.fields-instance-wage-charge {
  display: grid;
  grid-template-columns: 1fr;
  gap: variables.$prisma-padding-small;
}

.agent-with-remove-button {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 1rem;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
}

.agent-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
  gap: 1rem;
}

.agent-list-with-icon {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
  gap: 1rem;
}

.icon-with-text {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.5rem;
}

.max-page-width {
  max-width: 80rem;
  min-width: 60vw;
  margin: 0 auto;
}

.can-load {
  transition: opacity 0.3s ease;

  & + idv-loading-indicator {
    transition: opacity 0.5s ease;
    display: none;
    opacity: 0;
  }

  &.is-loading {
    opacity: 0.4;
    & + idv-loading-indicator {
      display: block;
      z-index: 100;
      opacity: 0.8;
    }
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

mat-calendar.hide-header {
  .mat-calendar-header {
    display: none;
  }
}

.seconds-display {
  background-color: var(--mat-theme-col-primary);
  padding: 0 variables.$prisma-padding * 0.2;
  border-radius: 3px;
  color: white;
}

idv-date-range-picker .mat-mdc-select-value-text mat-select-trigger {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}

.cdk-overlay-pane .mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 30rem;
}

mat-toolbar {
  & mat-form-field.big-search {
    width: 100%;
    flex-grow: 5;
  }
  & mat-form-field.medium-search {
    width: 20vw;
  }

  .mat-mdc-paginator {
    background: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;
  }

  .mat-toolbar-row + .mat-toolbar-row {
    height: var(--mat-toolbar-standard-height) / 2;
  }

  mat-toolbar-row {
    column-gap: 1rem;
  }
}

.mat-toolbar.mat-toolbar-multiple-rows + .mat-table-scroll-container {
  top: calc(var(--mat-toolbar-standard-height) * 1.8);
}

.mat-table-scroll-container {
  overflow: auto;
  position: absolute;
  top: var(--mat-toolbar-standard-height);
  bottom: 0;
  left: 0;
  right: 0;
}

.hide-ripple-line .mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-width: 0;
}

.mat-column-options-admin,
.mat-column-options-default {
  justify-content: flex-end;
}
