$prisma-padding: 2rem;
$prisma-padding-small: 1rem;

$prisma-col-approved: #1bb525;

$avatar-size: 2.5rem;

$loading-indicator-size: 5rem;

$edit-icon-size: 3.5rem;
